import { Suspense, useEffect, useState } from "react";
import { Switch, BrowserRouter } from "react-router-dom";

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

import { Spin } from "antd";
import { isEmpty } from "./_dash";
import { getAuthUserDetail } from "./actions";

import { NON_LOGIN_ROUTES, LOGGED_IN_ROUTES } from "./routes";

import AccountHook from "./hooks/account";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";

const App = ({ accountData, setAccountData }) => {
  const isLoggedIn = !isEmpty(accountData);

  const [loading, setLoading] = useState(false);
  const [appRoutes, setAppRoutes] = useState(NON_LOGIN_ROUTES);

  if (loading) {
    return <Spin />;
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<Spin />}
    >
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="app-loader">
              <Spin size="large" />
            </div>
          }
        >
          <Switch>
            {appRoutes.map((route, i) => {
              return <RouteWithSubRoutes key={i} {...route} />;
            })}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </CacheBuster>
  );
};

export default App;
