import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";

import "./styles/style.scss";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['https://sell-api.atlasautoglass.com/api']
      }),
    ],
    environment: process.env.SENTRY_ENVIRONMENT,
    tracesSampleRate: process.env.SENTRY_REPORTING_FREQUENCY ? process.env.SENTRY_REPORTING_FREQUENCY : 1.0,
    tracePropagationTargets: ["localhost", "https://sell-api.atlasautoglass.com/api"],
    replaysSessionSampleRate: process.env.NODE_ENV === "development" ? 0.1 : 0.3,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ],
  });
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);