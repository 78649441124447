import { Avatar, Col, Row, Space, Menu, Dropdown, Layout, message } from "antd";

import AccountHook from "../../hooks/account";

const DashboardLayout = ({ routes, accountData, setAccountData }) => {

  return (
    <Layout className="app-layout">

    </Layout>
  );
};

export default AccountHook(DashboardLayout);
