export const LOGIN_API_PATH = "login";
export const AUTH_USER_API_PATH = "auth_user";

export const QUOTES_API_PATH = "quote";
export const ADD_QUOTES_API_PATH = `${QUOTES_API_PATH}/add`;
export const GET_WANTED_SUCCESS_ACTION_API_PATH = `${QUOTES_API_PATH}/success-action`;

export const VEHICLES_API_PATH = "vehicle";
export const GET_ALL_FILTERED_VEHICLES_API_PATH = `${VEHICLES_API_PATH}/all-filtered?search_term={searchTerm}`;

export const NHTSA_INTEGRATION_API_PATH = "nhtsa-integration";
export const GET_CAR_INFO_VIN_LOOKUP_API_PATH = `${NHTSA_INTEGRATION_API_PATH}/get-vehicle-info?vin={vin}`;

export const DELETE_FILE_API_PATH = "file/delete-quote-photo?file_id={fileId}&quote_id={quoteId}";