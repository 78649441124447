import React from "react";
import {
  HomeOutlined,
} from "@ant-design/icons";

import DashboardLayout from "./layouts/dashboard";
import NotFoundPage from "./pages/404Page";

const QuoteStepsPage = React.lazy(() =>
  import("./pages/quoteSteps")
)
const QuoteCompletePage = React.lazy(() =>
  import("./pages/quoteComplete")
)

export const INDEX_ROUTE = "/";

export const QUOTE_STEPS_ROUTE = "/quote-steps";
export const QUOTE_COMPLETE_ROUTE = "/quote-complete";
// ROUTES
export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "quote-complete",
    path: QUOTE_COMPLETE_ROUTE,
    component: QuoteCompletePage,
  },
  {
    exact: true,
    name: "quote-steps",
    path: QUOTE_STEPS_ROUTE,
    component: QuoteStepsPage,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [

  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [

    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Home",
    route: INDEX_ROUTE,
    icon: <HomeOutlined />,
  },
];
