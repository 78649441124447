import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "../global_constants";
import axios from "axios";

const cancelInFlightGetRequest = (path) => {
  const cancelTokenSource = axios.CancelToken.source();
  return {
    request: instance.get(path, {
      cancelToken: cancelTokenSource.token,
    }),
    cancelTokenSource,
  };
};

export const addQuote = (payload) => {
  let path = endpoints.ADD_QUOTES_API_PATH;
  return instance.post(path, payload);
};

export const getSuccessAction = () => {
  let path = endpoints.GET_WANTED_SUCCESS_ACTION_API_PATH;
  return instance.get(path);
}

export const getAllFilteredVehicles = (searchTerm) => {
  let path = endpoints.GET_ALL_FILTERED_VEHICLES_API_PATH.replace("{searchTerm}", searchTerm);
  return instance.get(path);
};

export const getCarInfoForVinLookup = (vin) => {
  let path = endpoints.GET_CAR_INFO_VIN_LOOKUP_API_PATH.replace("{vin}", vin);
  return instance.get(path);
};

export const deleteFile = (fileId, quoteId) => {
  let path = endpoints.DELETE_FILE_API_PATH.replace("{fileId}", fileId).replace("{quoteId}", quoteId);
  return instance.get(path);
};