export const AUTHORIZATION_KEY = "atlas_intake_form";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const displayDateTimeFormat = 'MM/DD/YYYY hh:mm A';
export const displayTimeOnlyFormat = 'hh:mm A';
export const chatDisplayDateTimeFormat = 'MM/DD hh:mm A';
export const backendDisplayDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const phoneNumberMaskFormat = "(111) 111-1111"
export const phoneNumberMaskFormatNines = "(999) 999-9999"
export const callRailLogPhoneNumberMaskFormat = "111-111-1111"

export const GLASS_TYPE_CASCADER_VALUES = [
  {
    "value": "Windshield",
    "label": "Windshield",
  },
  {
    "value": "DriverLeft",
    "label": "Driver (Left)",
    "children": [
      {
        "label": "Front",
        "value": "FrontDriverWindow"
      },
      {
        "label": "Rear",
        "value": "RearDriverWindow"
      },
    ]
  },
  {
    "value": "PassengerRight",
    "label": "Passenger (Right)",
    "children": [
      {
        "label": "Front",
        "value": "FrontPassengerWindow"
      },
      {
        "label": "Rear",
        "value": "RearPassengerWindow"
      },
    ]
  },
  {
    "value": "BackGlassBackWindshield",
    "label": "Back Glass"
  },
  {
    "value": "QuarterGlass",
    "label": "Quarter",
    "children": [
      {
        "label": "Left",
        "value": "LeftQuarterGlass"
      },
      {
        "label": "Right",
        "value": "RightQuarterGlass"
      },
    ]
  },
  {
    "value": "VentGlass",
    "label": "Vent",
    "children": [
      {
        "label": "Left",
        "value": "LeftVentGlass"
      },
      {
        "label": "Right",
        "value": "RightVentGlass"
      },
    ]
  },
  {
    "value": "Sunroof",
    "label": "Sunroof"
  },
  {
    "value": "Other",
    "label": "Other (specify)"
  },
]

export const WORK_TYPES = [
  {
    "value": "GlassReplacementJob",
    "label": "Glass Replacement"
  },
  {
    "value": "ChipRepairJob",
    "label": "Chip Repair"
  },
  {
    "value": "BodyAndPaintJob",
    "label": "Body & Paint"
  },
  {
    "value": "TintOnlyJob",
    "label": "Tint"
  },
  {
    "value": "VehicleDetailJob",
    "label": "Vehicle Detail"
  }
]